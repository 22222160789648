@use "sass:math"; @import "abstract";
.harvest-sale-top-filter {
  background-color: $white;
  display: flex;
  flex-direction: column;

  @include mq($from: desktop) {
    padding: 32px;
  }

  @include mq($from: tablet, $until: desktop) {
    padding-top: 14px;
  }

  @include mq($until: tablet) {
    padding-top: 14px;
  }

  &__teaser {
    @include mq($from: tablet) {
      margin-right: 30px;
      margin-top: -4px;
      width: rem(244);

      .teaser__ico {
        margin-top: -8px;
      }

      p {
        margin-top: 8px;
      }
    }
  }
}

.harvest-sale-top-filter-cover {
  position: relative;
}

.harvest-sale-top-filter-wrapper {
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;

  @include mq($from: desktop) {
    background-color: $white;
    max-width: 950px;
    padding-bottom: 20px;
  }

  @include mq($until: desktop) {
    max-width: 100vw;
    padding-bottom: 8px;
  }
}

.harvest-sale-top-filter-item-wrap {
  @include mq($from: desktop) {

    &:not(:first-child) {
      padding-top: 12px;
    }
  }

  @include mq($until: desktop) {
    max-width: 100vw;
    overflow: hidden;
  }
}

.harvest-sale-top-filter-item {
  display: flex;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include mq($from: tablet, $until: desktop) {
    padding-left: 40px;
  }

  @include mq($until: desktop) {
    overflow-y: auto;
  }

  @include mq($until: tablet) {
    padding-left: 16px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:not(:last-child) {
    @include mq($from: desktop) {
      margin-bottom: 8px;
    }
  }

  &__title {
    min-width: fit-content;
    padding-right: 16px;
    padding-top: 4px;

    @include mq($from: desktop) {
      font-size: rem(18);
    }

    @include mq($until: desktop) {
      font-weight: 400;
      opacity: 0.5;
    }
  }

  &__buttons {
    @include scaledRem('margin-left', -8);

    @include mq($from: desktop) {
      flex-wrap: wrap;
    }

    @include mq($until: desktop) {
      flex-wrap: nowrap !important;
      padding-right: 44px;
    }
  }

  &__button {
    @include mqProp('margin', 0 6px 12px, 0 6px 8px);

    line-height: 21px;
    margin-left: 6px;
    margin-right: 6px;
    padding-bottom: 4px;
    padding-top: 3px;

    &:not(.button_checkbox-active) {
      @include mq($from: desktop) {
        border-color: #dddedc !important;
      }
    }

    &:not(.button_checkbox-active) {
      @include mq($until: desktop) {
        border-color: $white;
      }
    }
  }

  &__reset {
    margin-left: unset;

    @include mq($until: desktop) {
      position: absolute;
      right: 6px;
    }
  }
}

.harvest-sale-filter-toggle-button {
  align-items: center;
  background-color: $white;
  border: 2px solid $white;
  display: flex;
  justify-content: space-between;
  transition: border-color 200ms $sharp;
  width: 100%;
  will-change: border-color;

  @include mq($from: tablet) {
    padding: 10px 24px;
  }

  @include mq($until: desktop) {
    height: 48px;
  }

  @include mq($until: tablet) {
    border-radius: unset;
    color: $black;
    padding: 8px 14px;
  }

  span {
    @include scaledRem('font-size', 12);
  }

  &:hover {
    border-color: $green;
  }

  &__icon {
    color: $black
  }
}

.harvest-sale-filter-modal {
  background-color: $white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1998;

  @include mq($until: tablet) {
    padding: 76px 24px 0;
  }

  @include mq($from: tablet, $until: desktop) {
    padding: 125px 38px 64px;
  }

  &__filters {
    margin-right: -16px;
    padding-right: 16px;
  }

  &__actions {
    @include mq($until: tablet) {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column-reverse;
      flex-shrink: 0;
      margin-left: -24px;
      padding-bottom: 16px;
      padding-top: 16px;
      width: 100vw;

      .button {
        margin-left: auto !important;
      }
    }
  }

  &__close-button {
    @include mq($from: tablet, $until: desktop) {
      position: absolute;
      right: 32px;
      top: 130px;
    }

    @include mq($until: tablet) {
      position: absolute;
      right: 24px;
      top: 77px;
    }
  }

  &__filters {
    height: calc(100vh - 279px);
    overflow-y: auto;

    @include mq($until: tablet) {
      padding-bottom: 48px;
    }

    @include mq($from: tablet, $until: desktop) {
      padding-bottom: 56px;
    }
  }

  &__button {
    @include mq($until: desktop) {
      font-size: mRem(12);
      min-width: 218px;
    }
  }
}

.harvest-sale-page-text-with-icon {
  font-size: rem(14);

  svg {
    margin-left: 3px;
  }
}
