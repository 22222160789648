@use "sass:math"; @import "abstract";
.own-offer__button {
  @include mq($until: tablet) {
    background-color: $white;
    bottom: 76px;
    left: 50%;
    min-width: 165px;
    position: fixed;
    transform: translate(-50%, 0);
    z-index: 22;

    &_with-cookie-banner {
      bottom: 196px;
    }
  }
}
