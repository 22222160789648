@use "sass:math"; @import "abstract";
.harvest-sale-dropdown-filters {

  &__dropdown {
    @include mq($from: desktop) {
      width: rem(314);
    }

    @include mq($until: desktop) {

      .rs-picker-menu.rs-picker-select-menu {
        top: 48px !important;
      }
    }
  }
}

.harvest-sale-dropdown-filters-grid {
  @include mq($until: desktop) {
    display: grid;
    grid-column-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }
}
