@use "sass:math"; @import "abstract";
.no-template-notice {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 56px;

  &__description {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  
    @include mq($until: tablet) {
      margin: 0 16px 16px;
    }
  }

  &__title {
    @include mqProp('margin-top', 16px, 9px);

    margin-bottom: 8px;

    @include mq($until: tablet) {
      font-size: mRem(18);
      line-height: em(24, 18);
    }
  }

  &__icon {

    &.glass-morph-icon {
      background-size: cover;
      height: 80px;
      width: 80px;

      &::before {
        background-size: cover;
      }
    }
  }
}
