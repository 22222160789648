@use "sass:math"; @import "abstract";
.harvest-sale-left-filter {

  &__select-item {
    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 22px;
    }
  }

  &__rest-filters {
    @include mq($from: desktop) {
      margin-top: 49px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-top: 40px;
    }

    @include mq($until: tablet) {
      margin-top: 24px;
    }
  }

  .checkbox-container__label {
    @include mq($from: tablet) {
      max-width: 220px;
    }
  }
}
