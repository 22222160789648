@use "sass:math"; @import "abstract";
.harvest-sale-lot-preview {
  background-color: $white;
  padding: 16px;

  @include mq($from: tablet) {
    margin-bottom: 4px;
    margin-right: 4px;
    padding: 24px 16px;
    width: 420px;
  }

  @include mq($from: tablet, $until: desktop) {
    min-height: 320px;
  }

  @include mq($until: tablet) {
    margin-bottom: 2px;
    margin-left: -$mobilePageWrapperPadding;
    width: 100vw;
  }

  &__button {
    @include mq($until: tablet) {
      margin-left: 0;
      margin-right: 0;
      min-width: 0;
    }

    @include mq($until: 353px) {
      margin-top: 16px;
      width: 100%;
    }
  }

  &__validTo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-right: 8px;
    margin-top: 16px;

    @include mq($from: tablet) {
      justify-content: end;
    }
  }
}

.validToDate {
  background-color: $color-control-bg-secondary;
  border-radius: 8px;
  padding: 4px 8px;
}
