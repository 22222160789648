@use "sass:math"; @import "abstract";
.harvest-sale-dropdown_customized-footer {

  .dropdown-list-footer {
    padding-top: 32px;
  }

  .rs-picker-none {
    display: none;
  }
}
