@use "sass:math"; @import "abstract";
.culture-sale-request {
  display: grid;
  gap: 24px;
  grid-template-columns: 3fr 1fr;

  @include mq($until: desktop) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.culture-sale-request-form {
  @include mqProp('padding', 32px, 16px);
  @include mqProp('min-width', rem(926), 100%, 100%);

  background-color: $white;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 24px;

  &__delivery-options {
    @include mqProp('grid-template-columns', 1fr 2fr, 1fr, 1fr 1fr);

    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 24px;


    &--elevator {
      grid-template-columns: 1fr 1fr;


      @include mq($until: tablet) {

        .dropdown-view.regionCode {
          grid-area: 2 / 1 / 3 / 3;
        }

        .dropdown-view.elevatorId {
          grid-area: 3 / 1 / 4 / 3;
        }
      }

      .dropdown-view:first-child {
        grid-area: 1 / 1 / 2 / 3;
      }

      .dropdown-view.regionCode {
        grid-area: 2 / 1 / 3 / 2;
      }

      .dropdown-view.elevatorId {
        grid-area: 2 / 2 / 3 / 3;
      }
    }
  }

  &__details {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 24px;


    @include mq($from: tablet) {
      grid-template-columns: repeat(2, max(49%));
    }
  }

  &__desired-price {

    .input-wrapper__input {
      display: grid;
      grid-template-columns: 1fr auto;
    }
  }

  &__button {
    @include mq($until: desktop) {
      width: 100%;
    }

    &__icon {
      color: $white;
      margin-right: 8px;
    }
  }
}

.culture-sale-request-form-buyers-notice {
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;

  @include mq($until: tablet) {
    grid-template-columns: 1fr;
  }

  &__info {
    @include mqProp('max-width', rem(520), mRem(280), 100%);

    align-items: flex-start;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
  }
}

.culture-sale-request-price-prediction-top {
  position: sticky;
  top: 0;
  transition: 300ms ease-in-out;
  transition-property: transform;
  width: 100%;
  will-change: transform;
  z-index: 200;

  @include mq($from: desktop) {

    .culture-sale-request-price-prediction-right-wrapper {
      background-color: $white;
      padding: 32px;
    }
  }

  @include mq($from: tablet, $until: desktop) {

    &_up {
      transform: translateY(120px);
    }
  }

  @include mq($until: tablet) {
    right: 0;

    &_up {
      transform: translateY(50px);
    }
  }
}

.top-notice-with-price {
  display: flex;
}

.culture-sale-request-price-prediction-right-wrapper {
  height: 100%;

  @include mq($until: desktop) {
    background-color: $white;
    display: grid;
    grid-column-gap: 20px;
    grid-template-areas:
      'top-notice-title top-notice-description'
      'top-notice-with-price middle-price';
    grid-template-columns: 1fr 1fr;
    padding: 24px;

    @include mq($from: tablet, $until: desktop) {

      &_up {
        transform: translateY(120px);
      }
    }

    @include mq($until: tablet) {
      right: 0;

      &_up {
        transform: translateY(50px);
      }
    }

    #top-notice-title {
      grid-area: top-notice-title;
    }

    #top-notice-description {
      grid-area: top-notice-description;
    }

    #top-notice-with-price {
      grid-area: top-notice-with-price;
    }

    #middle-price {
      grid-area: middle-price;
    }
  }
}


