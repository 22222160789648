@use "sass:math"; @import "abstract";
.demo-harvest-sale-request {
  @include mqProp('padding', 32px, 24px 16px);
  @include mqProp('width', rem(388), 100%);

  background-color: $white;

  &__field {
    @include mqProp('margin-bottom', 16px, 8px);

    .dropdown-view {
      min-width: 100%;
    }
  }

  &__volume {

    .dropdown-view {
      @include mqProp('margin-bottom', 16px, 8px);
    }
  }
}
