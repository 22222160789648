@use "sass:math"; @import "abstract";
.template {
  @include mqProp('padding', 24px 40px, 16px);

  background: $white;
  transition: box-shadow 250ms $sharp;
  will-change: box-shadow;

  @include mq($until: mobile) {
    flex-wrap: wrap;
  }

  &__header, &__footer {
    display: flex;
    justify-content: space-between;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__footer {
    @include mq($from: tablet) {
      align-items: flex-end;
    }

    @include mq($from: tablet) {
      margin-top: 16px;
    }
  }

  &:hover, &:focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  &:not(:last-child) {
    @include mqProp('margin-bottom', 16px, 2px);
  }

  &__button {
    padding: 9px 24px 10px;

    @include mq($until: tablet) {
      margin-right: 0;
      min-width: auto;
    }

    @include mq($from: tablet) {
      min-height: rem(40);
    }
  }

  &__validTo {
    column-gap: 8px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-right: 8px;
    margin-top: 16px;
    white-space: nowrap;

    @include mq($from: tablet) {
      justify-content: end;
    }
  }

  &__harvestName {
    margin-bottom: 4px;
  }

  .validToDate {
    background-color: $color-control-bg-secondary;
    border-radius: 8px;
    padding: 4px 8px;
  }
}

.template-prices {
  @include mq($from: tablet) {
    justify-content: flex-start;
  }

  @include mq($from: tablet) {
    width: rem(350);
  }

  @include mq($until: tablet) {
    justify-content: space-between;
    margin-top: 16px;
  }

  @include mq($until: mobile) {

    div.product-price > div:first-child {
      font-size: mRem(16);
      line-height: em(22.4, 16);
    }

    .product-price__fraction {
      font-size: mRem(14);
      line-height: em(15, 14);
    }
  }

  &__item {
    @include mq($from: tablet) {
      width: 33%;

      &:not(:first-child) {
        margin-left: 27px;
      }
    }
  }
}

.template-price-details {
  background-color: $color-control-bg-secondary;
  border-radius: 8px;
  padding: 4px 8px;
}

.template-buyer-info {
  @include mq($from: tablet, $until: desktop) {
    max-width: 45%;
  }

  @include mq($from: desktop) {
    max-width: 55%;
  }
}

.template-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;

  @include mq($from: tablet) {
    justify-content: flex-end;
  }
}

@include mq($until: tablet) {

  .template {

    &__harvestName {
      margin-bottom: 8px;
    }

    &__qualityParams {
      margin-bottom: 16px;
    }
  }
}
