@use "sass:math"; @import "abstract";
.harvestSaleOrderRadio {
  margin-bottom: 24px;

  &__notice {
    background-color: $color-status-error-default;
    border: 2px solid $color-control-border-error;
    margin-top: 4px;
    padding: 0;
  }

  &__noticeTitle {
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
  }

  &__noticeIcon {
    color: $color-text-error;
    margin-right: 8px;
  }

  &__item {
    border: 2px solid $lightGray;
    flex-direction: row-reverse;
    padding: 12px 16px;
    will-change: background-color, border;

    transition: {
      duration: 0.2s;
      property: background-color, border;
      timing-function: $sharp;
    }

    &:not(:first-child) {
      margin-top: 4px;
    }

    &:hover,
    &:focus {
      border-color: $green;

      input:not(:checked) {

        ~ .custom-radio__checkmark {
          background-color: $lightGray !important;
          border-color: $lightGray !important;
        }
      }
    }

    &--checked {
      background-color: $lightGreen;
      border-color: $lightGreen;
    }

    & > .custom-radio__checkmark {
      margin: 0 0 14px;
    }
  }
}
