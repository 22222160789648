@use "sass:math"; @import "abstract";
.lotBanner {
  align-items: flex-start;
  background-color: $color-control-bg-accent-hover;
  background-image: url('/assets/images/harvestSale/lotBanner.png');
  background-position: bottom left;
  background-repeat: no-repeat;
  color: $white;
  display: flex;
  flex-shrink: 0;
  transition: box-shadow 250ms $sharp;
  will-change: box-shadow;

  &:not(&_mobileView) {
    @include mqProp('padding', 24px 40px, 24px 32px);

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &_mobileView {
    @include mqProp('padding', 32px, 24px 32px);

    flex-direction: column;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  &__title {
    @include scaledRem('font-size', 36);

    font-family: Masiva;
    font-weight: 600;
    line-height: em(24, 18);
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .lotBanner_mobileView & {
      margin-bottom: 16px;
    }

    .lotBanner:not(.lotBanner_mobileView) & {
      @include mqProp('margin-bottom', 8px, 16px);
    }
  }

  &__description {
    color: $color-text-disabled;

    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 28px;
    }
  }

  &__action {
    @include scaledRem('width', 177);

    &_button {
      color: $color-text-primary;
    }

    .lotBanner_mobileView & {
      margin-left: 0;
      margin-top: 24px;
      min-width: 0;

      @include mq($from: tablet) {
        margin-top: auto;
      }
    }

    .lotBanner:not(.lotBanner_mobileView) & {
      @include mq($until: tablet) {
        margin-left: 0;
        margin-top: 24px;
        min-width: 0;
      }
    }
  }

  &__titleWrapper {
    margin-bottom: 16px;
  }
}
