@use "sass:math"; @import "abstract";
.cultures-sales-lots-templates {
  padding: 32px;

  @include mq($until: tablet) {
    padding: 8px;
  }
}

.cultures-sales-lots-templates-list {
  display: flex;
  flex-wrap: nowrap;
  grid-column-gap: 0;
  overflow-x: auto;
  padding-right: 8px;


  @include mq($from: desktop) {
    
    &::-webkit-scrollbar {
      background-color: #F5F5F5;
      width: 5px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #fffefe;
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(141, 140, 140, 0.776);
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: rgb(173, 165, 165);
      border-radius: 10px;
      width: 5px;
    }
  }

  @include mq($until: desktop) {
    
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.culture-sale-lot {
  @include mqProp('padding', 32px, 16px);
  
  background-color: $white;
    
  @include mq($from: tablet) {
    margin-bottom: 4px;
    margin-right: 4px;
    min-width: 420px;
  }
  
  @include mq($from: tablet, $until: desktop) {
    min-height: 320px;
  }
    
  @include mq($until: tablet) {
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 2px;
    min-width: 312px;
    padding: 16px;

    .lot-price {

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }

  &__show-all-offers {
    align-items: center;
    justify-content: center;

    @include mq($until: tablet) {
      width: 200px;
    }
  }
}
